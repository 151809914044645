//
// Landing elements
//

// Variables
$landing-dark-color: #13263c;
$landing-header-height: 100px;
$landing-header-height-tablet-and-mobile: 70px;
$landing-header-sticky-height: 70px;
$landing-header-sticky-height-tablet-and-mobile: 70px;

// Utility classes
.landing-dark-bg {
  background-color: $landing-dark-color;
}

.landing-dark-color {
  color: $landing-dark-color;
}

.landing-dark-border {
  border: 1px dashed #2c3f5b;
}

.landing-dark-separator {
  border-top: 1px dashed #2c3f5b;
}

.landing-curve {
  position: relative;

  svg {
    position: relative;
    top: 0;
    display: block;
  }
}

// Landing header
.landing-header {
  display: flex;
  align-items: center;
  height: $landing-header-height;

  // Logos
  .logo-default {
    display: block;
  }

  .logo-sticky {
    display: none;
  }

  // Menu
  .menu {
    // Menu link
    .menu-link {
      &.active {
        color: $white;
      }
    }

    // Header menu sticky mode
    [data-kt-sticky-landing-header='on'] & {
      // Menu link
      .menu-link {
        color: $white;
        text-shadow: none;
        &.active {
          color: $primary;
          text-shadow: none;
          // background-color: rgba(#eff2f5, 0.4);
        }
      }
    }
  }

  // Sticky header modes
  [data-kt-sticky-landing-header='on'] & {
    padding: 0;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background-color: #052a55;
    // --tw-bg-opacity: 1;
    // background-color: hsl(var(--nextui-background)/var(--nextui-background-opacity,var(--tw-bg-opacity)));
    // box-shadow: 0px 10px 30px 0px rgba(54, 40, 71, 0.196);
    // color: rgb(0, 0, 0);
    // text-decoration: none;
    // white-space: normal;
    
    // vertical-align: top;
    // text-align: right;
    // line-height: 80px;
    // // letter-spacing: -1px;
    // font-weight: 800;
    // font-size: 90px;
    // filter: none;
    // transform-origin: 50% 50%;
    // opacity: 1;
    // transform: translate(0px, 0px);
    // visibility: visible;
    // background: rgba(255,255,255,.1);
    
    // backdrop-filter: blur(500px);

    // Logos
    .logo-sticky {
      display: block;
    }

    .logo-default {
      display: none;
    }
  }
}

// Fix body padding top when Landing sticky header is on
// body[data-kt-sticky-landing-header='on'] {
//   padding-top: $landing-header-height;
// }

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  // Landing header
  .landing-header {
    // height: $landing-header-height-tablet-and-mobile;

    // Menu wrapper
    .landing-menu-wrapper {
      position: relative;
      overflow: auto;
    }

    // Sticky header modes
    // [data-kt-sticky-landing-header='on'] & {
    //   // height: $landing-header-sticky-height-tablet-and-mobile;
    // }

    // Menu
    .menu {
      // Menu link
      .menu-link {
        &.active {
          color: $primary;
          text-shadow: none;
          // background-color: rgba(#eff2f5, 0.4);
        }
      }
    }
  }

  // Fix body padding top when Landing sticky header is on
  // body[data-kt-sticky-landing-header='on'] {
  //   padding-top: $landing-header-height-tablet-and-mobile;
  // }
}
