@font-face {
  font-family: 'Outfit';
  src: url(../Outfit-VariableFont_wght.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MontserratR';
  src: url(../Montserrat-Regular.otf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'MontserratBlack';
  src: url(../Montserrat-Bold.otf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTProBdCn';
  src: url(../HelveticaNeueLTProBdCn.otf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNeueLTProLtCn';
  src: url(../HelveticaNeueLTProLtCn.otf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Paris2024';
  src: url(../Paris2024-Variable.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}


.paris-text {
  font-family: 'Paris2024', sans-serif;
}

.montseB-text {
  font-family: 'MontserratBlack', sans-serif;
}

body {
  font-family: 'Outfit', sans-serif;
}
.close {
  border: 0;
  outline: none !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  padding: 0;
  /* height: calc(1.5em + (1.5rem + 1px)); */
  width: calc(1.5em + (1.5rem + 2px));
  text-transform: uppercase;

  background-color: transparent !important;
}

.close span {
  padding: 0;
  font-size: 1.8em;
}

.close:hover {
  color: #009ef6 !important;
}

.rdt_TableBody {
  min-height: 300px !important;
}

.alert-xs {
  padding: 0.5rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-radius: 0.2rem !important;
  margin: 0.2rem !important;
  /* width: 50%; */
}
/* ********************************************* */

.text-danger-fill {
  fill: #fd397a;
}

.text-success-fill {
  fill: #1dc9b7;
}

.w-icon-circle {
  width: 11px
}
/* ********************************************* */

#contenedor-pdf {
  width: 100%;
  height: 450px;
  border: solid 0.5px #ccc !important;
}

#contenedor-pdf-juridico {
  width: 100%;
  height: 350px;
  border: solid 0.5px #ccc !important;
}

#contenedor-pdf img {
  width: 100%;
  height: 100%;
}

.Visor {
  border: solid 0.5px #ccc !important;
  border-radius: 5px;
}

/* Avatar  */
.kt-avatar {
  position: relative !important;
  display: inline-block !important;
}

.kt-avatar .kt-avatar__holder {
  width: 160px !important;
  height: 160px !important;
  border-radius: 3px !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.kt-avatar .kt-avatar__upload {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}

.kt-avatar .kt-avatar__upload input {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
  opacity: 0;
}

.kt-avatar .kt-avatar__upload i {
  position: absolute;
  color: #009ef7;
  font-size: 0.9rem;
}

.kt-avatar .kt-avatar__upload:hover {
  transition: all 0.3s;
  background-color: #009ef7;
}

.kt-avatar .kt-avatar__upload:hover i {
  color: #ffffff;
}

.kt-avatar .kt-avatar__cancel {
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: auto;
  right: -10px;
  bottom: -5px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
  display: none;
}

.kt-avatar .kt-avatar__cancel i {
  transition: all 0.3s;
  color: #74788d;
  font-size: 0.9rem;
}

.kt-avatar .kt-avatar__cancel:hover i {
  transition: all 0.3s;
  color: #009ef7;
}

.kt-avatar.kt-avatar--changed .kt-avatar__cancel {
  display: flex;
}

.kt-avatar.kt-avatar--brand .kt-avatar__upload i {
  color: #009ef7;
}

.kt-avatar.kt-avatar--brand .kt-avatar__upload:hover {
  background-color: #009ef7;
}

.kt-avatar.kt-avatar--brand .kt-avatar__upload:hover i {
  color: #ffffff;
}

.kt-avatar.kt-avatar--light .kt-avatar__upload i {
  color: #ffffff;
}

.kt-avatar.kt-avatar--light .kt-avatar__upload:hover {
  background-color: #ffffff;
}

.kt-avatar.kt-avatar--light .kt-avatar__upload:hover i {
  color: #282a3c;
}

.kt-avatar.kt-avatar--dark .kt-avatar__upload i {
  color: #282a3c;
}

.kt-avatar.kt-avatar--dark .kt-avatar__upload:hover {
  background-color: #282a3c;
}

.kt-avatar.kt-avatar--dark .kt-avatar__upload:hover i {
  color: #ffffff;
}

.kt-avatar.kt-avatar--primary .kt-avatar__upload i {
  color: #5867dd;
}

.kt-avatar.kt-avatar--primary .kt-avatar__upload:hover {
  background-color: #5867dd;
}

.kt-avatar.kt-avatar--primary .kt-avatar__upload:hover i {
  color: #ffffff;
}

.kt-avatar.kt-avatar--success .kt-avatar__upload i {
  color: #1dc9b7;
}

.kt-avatar.kt-avatar--success .kt-avatar__upload:hover {
  background-color: #1dc9b7;
}

.kt-avatar.kt-avatar--success .kt-avatar__upload:hover i {
  color: #ffffff;
}

.kt-avatar.kt-avatar--info .kt-avatar__upload i {
  color: #5578eb;
}

.kt-avatar.kt-avatar--info .kt-avatar__upload:hover {
  background-color: #5578eb;
}

.kt-avatar.kt-avatar--info .kt-avatar__upload:hover i {
  color: #ffffff;
}

.kt-avatar.kt-avatar--warning .kt-avatar__upload i {
  color: #ffb822;
}

.kt-avatar.kt-avatar--warning .kt-avatar__upload:hover {
  background-color: #ffb822;
}

.kt-avatar.kt-avatar--warning .kt-avatar__upload:hover i {
  color: #111111;
}

.kt-avatar.kt-avatar--danger .kt-avatar__upload i {
  color: #fd397a;
}

.kt-avatar.kt-avatar--danger .kt-avatar__upload:hover {
  background-color: #fd397a;
}

.kt-avatar.kt-avatar--danger .kt-avatar__upload:hover i {
  color: #ffffff;
}

.kt-avatar.kt-avatar--circle .kt-avatar__holder {
  border-radius: 50%;
}

.kt-avatar.kt-avatar--circle .kt-avatar__upload {
  right: 0px;
  top: 5px;
}

.kt-avatar.kt-avatar--circle .kt-avatar__cancel {
  right: 3px;
  bottom: 10px;
}

.kt-avatar.kt-avatar--outline .kt-avatar__holder {
  border: 3px solid #ffffff;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
}

.kt-avatar .kt-avatar__save {
  display: flex !important;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: auto;
  bottom: -5px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 10%);
  display: none;
}

.kt-avatar .kt-avatar__save:hover {
  color: #28c76f;
}

.modal-90w {
  width: 90%;
  max-width: none !important;
}

.modal-80w {
  width: 80%;
  max-width: none !important;
}

.modal-70w {
  width: 70%;
  max-width: none !important;
}

.modal-50w {
  width: 60%;
  max-width: none !important;
}

.modal-40w {
  width: 60%;
  max-width: none !important;
}

input[readonly] {
  background-color: hsl(0, 0%, 95%) !important;
  color: hsl(0, 0%, 60%) !important;
}

.modal-60w {
  width: 95%;
  max-width: none !important;
}

@media (min-width: 1199.98px) {
  .responsiveMap {
    height: 800px !important;
    width: 100% !important;
    z-index: 2 !important;
  }

  .modal-60w {
    width: 60%;
    max-width: none !important;
  }
}

@media (max-width: 1198px) {
  .responsiveMap {
    height: 800px !important;
    width: 100% !important;
    z-index: 2 !important;
  }
}

@media (max-width: 991.98px) {
  .responsiveMap {
    height: 800px !important;
    width: 100% !important;
    z-index: 2 !important;
  }
}

@media (max-width: 767.98px) {
  .responsiveMap {
    height: 400px !important;
    width: 100% !important;
    z-index: 2 !important;
  }
}

@media (max-width: 575.98px) {
  .responsiveMap {
    height: 400px !important;
    width: 100% !important;
    z-index: 2 !important;
  }
}

.card-person {
  /* background-color: rgb(255, 255, 255); */
  color: rgb(33, 43, 54);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /* background-image: none;
    overflow: hidden;
    position: relative; */
  box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
  border-radius: 16px;
  /* z-index: 0;
    display: flex;
    align-items: center;
    padding: 24px; */
}

.dz-thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eee;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
}

.dz-thumb-inner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

.dz-img {
  display: block;
  width: 100%;
  height: 100%;
}

.upload-file {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: auto;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #009ef6;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.thumb-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
}

.breadcrumb-chevron {
  border: none;
  padding: 0 1rem;
  content: "\e844";
  /* font-family: "feather"; */
  font-size: 14px;
  position: relative;
  top: 1px;
}

.btn-accion {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 700;
  line-height: 1.71429;
  font-size: 0.8125rem;
  text-transform: capitalize;
  font-family: 'Public Sans', sans-serif;
  min-width: 64px;
  padding: 3px 9px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: inherit;
  border: 1px solid rgba(145, 158, 171, 0.32) !important;
}

.text-content-card {
  font-weight: 600;
  line-height: 1.57143;
  font-size: 0.875rem;
  font-family: 'Public Sans', sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* diseño del input file  */
.file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}
.file-file-info-p {
  opacity: 0;
  height: 100vh;
}


.file-input label {
  display: block;
  position: relative;
  width: 100%;
  height: 36px;
  border-radius: 25px;
  border-color: #181c32;
  background-color: #181c32;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease-out;
  margin-top: 18px;
}
.file-info-p label {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  /* border-color: #181c32;
  background-color: #181c32; */
  /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4); */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease-out;
  /* margin-top: 35px; */
}

.file-name {
  position: absolute;
  bottom: -35px;
  left: 10px;
  font-size: 0.85rem;
  color: #2e40d4;
}
.file-name-info-p {
  position: absolute;
  bottom: -35px;
  left: 10px;
  font-size: 0.85rem;
  /* color: #2e40d4; */
}

.file:hover+label,
.file:focus+label {
  transform: scale(1.02);
  border: #2e40d4;
  background-color: #384ad7;
}

/* Adding an outline to the label on focus */
.file:focus+label {
  outline: 1px solid #2e40d4 !important;
  outline-color: #2e40d4 !important;
  /* outline: -webkit-focus-ring-color auto 2px; */
}

.file-file-info-p :hover+label,
.file-file-info-p :focus+label {
  transform: scale(1.02);
  border: #2e40d4;
  /* background-color: #384ad7; */
}

/* Adding an outline to the label on focus */
/* .file-file-info-p :focus+label { */
  /* outline: 1px solid #2e40d4 !important;
  outline-color: #2e40d4 !important; */
  /* outline: -webkit-focus-ring-color auto 2px; */
/* } */

.text-small {
  font-size: 10px !important;
}

/* Divider */

.divider {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  margin: 1rem 0;
}

.divider .divider-text {
  position: relative;
  display: inline-block;
  font-size: 0.9375rem;
  padding: 0 1rem;
  background-color: #fff;
}

.divider .divider-text svg {
  height: 1rem;
  width: 1rem;
}

.divider .divider-text:before,
.divider .divider-text:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 9999px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.divider .divider-text:before {
  right: 100%;
}

.divider .divider-text:after {
  left: 100%;
}

.divider.divider-left .divider-text {
  float: left;
  padding-left: 0;
}

.divider.divider-left .divider-text:before {
  display: none;
}

.divider.divider-left-center .divider-text {
  left: -25%;
}

.divider.divider-right .divider-text {
  float: right;
  padding-right: 0;
}

.divider.divider-right .divider-text:after {
  display: none;
}

.divider.divider-right-center .divider-text {
  right: -25%;
}

.divider.divider-dotted .divider-text:before,
.divider.divider-dotted .divider-text:after {
  border-style: dotted;
  border-width: 1px;
  border-top-width: 0;
  border-color: rgba(0, 0, 0, 1);
}

.divider.divider-dashed .divider-text:before,
.divider.divider-dashed .divider-text:after {
  border-style: dashed;
  border-width: 1px;
  border-top-width: 0;
  border-color: rgba(0, 0, 0, 1);
}

.divider.divider-start .divider-text {
  float: left;
  padding-left: 0;
}

.divider.divider-start-center .divider-text {
  left: -25%;
}

.form-control {
  padding: 8px !important;
}

.btn.btn-sm {
  padding: 11px !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.form-control[readonly] {
  background-color: #eff2f5 !important;
}

.size-form {
  min-height: 400px;
}

.size-label-check {
  min-height: 153.775px;
}

body iframe {
  display: none !important;
}

.card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.082) !important;
}

.card-datatbl {
  border-radius: 12px;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.082) !important;
}

.classic-editor-word-count {
  background-color: var(--color-seal-3);
  border-style: solid;
  border-color: var(--ck-color-base-border);
  border-width: 0 1px 1px;
}

.page-pdf-custom {
  height: 297mm;
  width: 210mm;
}

.page-pdf-custom-body {
  size: 7in 9.25in;
  margin: 27mm 16mm 27mm 16mm;
}


/* .my-masonry-grid */

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 20px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

.font-Feedback {
  font-size: 0.925rem;
}
/* mixin for multiline */
.block-with-text {
  overflow: hidden;
  position: relative;
  line-height: 1.2em;
  max-height: 6em;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
}
.block-with-text:before {
  content: '...';
  position: absolute;
  right: 0;
  bottom: 0;
}
.block-with-text:after {
  content: '';
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: white;
}

.img-icon-svg {
  --color_fill: #F00;
}

.ck-content {
  height: 580px;
}

.text-break {
  word-break: break-word;
}

/* ----------------------- */
/* context menu */
/* Estilos para el menú contextual */
.react-contextmenu {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  padding: 4px 0;
  min-width: 150px;
  width: 200px;
  max-height: 200px;
  overflow: auto;
  position: absolute; /* Asegura que el menú sea posicionado */
  z-index: 9999; /* Establece un valor alto para asegurar que el menú aparezca en primer plano */
}

/* Estilos para cada ítem del menú */
.react-contextmenu-item {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

/* Estilos para el divisor del menú */
.react-contextmenu-item.react-contextmenu-divider {
  border-top: 1px solid #ccc;
  margin: 4px 0;
}

/* Estilos para el ítem del menú cuando está deshabilitado (disabled) */
.react-contextmenu-item.react-contextmenu-disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Estilos para el ítem del menú cuando el mouse pasa por encima (hover) */
.react-contextmenu-item:hover {
  background-color: #f2f2f2;
}

.arribadetodo {
  z-index: 9999 !important;
}

.react-select {
  z-index: 1000 !important;
}

.selector-react1 {
  z-index: 900 !important;
}

.selector-react2 {
  z-index: 800 !important;
}

.selector-react3 {
  z-index: 700 !important;
}

.selector-react4 {
  z-index: 600 !important;
}

.ck.ck-dropdown .ck-button.ck-dropdown__button:not(.ck-button_with-text){
  z-index: 0 !important;
}

.ck.ck-dropdown .ck-button.ck-dropdown__button{
  z-index: 0 !important;
}


@media screen and (max-width: 768px) {
  .cambia-tamaño {
  /* display: none; */
text-align: center;
  }
  }

  .file-info-p {
    /* position: relative; */
    border: 2px dashed #ccc;
    /* padding: 5px; */
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    transition: border-color 0.3s ease;
  }
  
  .file-info-p:hover,
  .file-info-p.dragover {
    border-color: #2792df; /* Cambia el color del borde cuando se arrastra un archivo sobre él */
    /* Cambia el color del borde cuando se arrastra un archivo sobre él */
  }
  
  input[type="file"] {
    display: none; /* Oculta el input de archivo predeterminado */
  }
  
  /* Estilos para el label y el párrafo */
  label {
    font-weight: bold;
    cursor: pointer;
    color: #2792df;
  }
  
  p.file-name {
    /* margin-top: 3px; */
    font-size: 14px;
  }
  
